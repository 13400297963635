var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app flex-row align-items-center guest" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { xs: "12", md: "8", lg: "6" } },
              [
                _c(
                  "b-card-group",
                  [
                    _c(
                      "b-card",
                      { staticClass: "p-3", attrs: { "no-body": "" } },
                      [
                        _c("b-card-body", [
                          _c(
                            "form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.validateBeforeSubmit.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "mx-auto d-block mb-4 navbar-brand-full",
                                attrs: {
                                  src: "img/brand/invoice-logo-2019.png",
                                  height: "95",
                                  alt: "RTNEST Inc. Logo"
                                }
                              }),
                              _c("h1", [_vm._v("Log In")]),
                              _c("p", { staticClass: "text-muted" }, [
                                _vm._v("Sign In to your account")
                              ]),
                              _c("flash-message", {
                                attrs: { autoHide: "", variant: "success" }
                              }),
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", { staticClass: "icon-user" })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|email",
                                        expression: "'required|email'"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      name: "email",
                                      placeholder: "Email",
                                      autocomplete: "username email"
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("email"),
                                    expression: "errors.has('email')"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle mb-3"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("email"),
                                      expression: "errors.has('email')"
                                    }
                                  ],
                                  staticClass: "mb-3 help is-danger"
                                },
                                [_vm._v(_vm._s(_vm.errors.first("email")))]
                              ),
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", { staticClass: "icon-lock" })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "password",
                                      name: "password",
                                      placeholder: "Password",
                                      autocomplete: "current-password"
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("password"),
                                    expression: "errors.has('password')"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle mb-3"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("password"),
                                      expression: "errors.has('password')"
                                    }
                                  ],
                                  staticClass: "mb-3 help is-danger"
                                },
                                [_vm._v(_vm._s(_vm.errors.first("password")))]
                              ),
                              _c(
                                "b-input-group",
                                { staticClass: "mb-2" },
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    { staticStyle: { "z-index": "99" } },
                                    [
                                      _c("b-input-group-text", [
                                        _c("i", {
                                          staticClass: "icon-settings"
                                        })
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'"
                                        },
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.account,
                                          expression: "account"
                                        }
                                      ],
                                      staticClass:
                                        "select-account form-control",
                                      attrs: { name: "account" },
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.account = $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "",
                                            disabled: "",
                                            selected: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "Account Type (Select Your Role)"
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.accounts, function(option) {
                                        return _c(
                                          "option",
                                          {
                                            attrs: { for: "" },
                                            domProps: { value: option.url }
                                          },
                                          [_vm._v(_vm._s(option.accountType))]
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "dropdown-icon fa fa-chevron-down position-absolute"
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("account"),
                                    expression: "errors.has('account')"
                                  }
                                ],
                                staticClass: "fa fa-exclamation-triangle"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("account"),
                                      expression: "errors.has('account')"
                                    }
                                  ],
                                  staticClass: "mb-3 help is-danger"
                                },
                                [_vm._v("The account type is required.")]
                              ),
                              _vm.url == "invoice.rtnest.ca" ||
                              _vm.url == "www.invoice.rtnest.ca"
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.robot,
                                        expression: "robot"
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'"
                                      }
                                    ],
                                    attrs: { type: "hidden", name: "robot" },
                                    domProps: { value: _vm.robot },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.robot = $event.target.value
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.signInError,
                                      expression: "signInError"
                                    }
                                  ],
                                  staticClass: "help is-danger"
                                },
                                [
                                  _c("h5", { staticClass: "mt-2" }, [
                                    _c("i", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.signInError,
                                          expression: "signInError"
                                        }
                                      ],
                                      staticClass: "fa fa-exclamation-triangle"
                                    }),
                                    _vm._v(
                                      " Please make sure the followings are correct"
                                    )
                                  ]),
                                  _c(
                                    "ol",
                                    {
                                      staticStyle: {
                                        "list-style-type": "upper-greek"
                                      }
                                    },
                                    [
                                      _c("li", [
                                        _vm._v(
                                          'You are using "Google Chrome" or compatible browser.'
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "Your user name is the email that your company was registered with."
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v("Your password is correct.")
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          "The role you choose is correct (e.g. If you want to submit field tickets/invoices, you are a vendor!)."
                                        )
                                      ])
                                    ]
                                  ),
                                  _c("p", [
                                    _vm._v(
                                      "Otherwise, contact admin for support "
                                    ),
                                    _c(
                                      "a",
                                      { attrs: { href: "tel:15872987997" } },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-phone fa-flip-horizontal"
                                        }),
                                        _vm._v("+1 (587) 298 7997")
                                      ]
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "b-input-group",
                                { staticClass: "cap" },
                                [
                                  _c("vue-recaptcha", {
                                    ref: "reCaptcha",
                                    staticClass: "mt-2",
                                    attrs: {
                                      sitekey:
                                        "6LfAwYcUAAAAACZe3pwLB7otQzENyRr_UlZshjx6"
                                    },
                                    on: {
                                      verify: _vm.onCaptchaVerified,
                                      expired: _vm.onCaptchaExpired
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("robot"),
                                    expression: "errors.has('robot')"
                                  }
                                ],
                                staticClass:
                                  "fa fa-exclamation-triangle mt-2 mb-2"
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.errors.has("robot"),
                                      expression: "errors.has('robot')"
                                    }
                                  ],
                                  staticClass: "mb-3 help is-danger"
                                },
                                [_vm._v("The reCaptcha is required.")]
                              ),
                              _c(
                                "b-row",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "col-md-5",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "w-100 px-5",
                                          attrs: {
                                            variant: "primary",
                                            type: "submit"
                                          }
                                        },
                                        [_vm._v("Login")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-md-right col-md-7",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "px-0",
                                          attrs: {
                                            to: { name: "Forgot" },
                                            variant: "link"
                                          }
                                        },
                                        [_vm._v("Forgot password?")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }